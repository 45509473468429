import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const WebDesignersAndDigitalMarketers = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				mark: file(relativePath: { eq: "testimonials/mark-wiffen.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				mattG: file(relativePath: { eq: "testimonials/MatthewGiles.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				tristan: file(relativePath: { eq: "testimonials/Tristan-Bund.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const mark = data.mark.childImageSharp.gatsbyImageData;
	const tristan = data.tristan.childImageSharp.gatsbyImageData;
	const mattG = data.mattG.childImageSharp.gatsbyImageData;
	const schemaImage = data.heroImg.publicURL;
	const title =
		"Architects & Building Professionals | Business & Executive Coaching in London";
	const description =
		"Architects and building professionals, while experts at shaping the world around us, can sometimes find themselves lost in the blueprints of their own business strategy. As they navigate through project work and client demands, it's crucial to step back and invest time into the structure of their business itself.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Architects & Building Professionals",
				item: {
					url: `${siteUrl}/industries/architects-and-building-professionals`,
					id: `${siteUrl}/industries/architects-and-building-professionals`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/architects-and-building-professionals`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business & Executive Coach for Architects & Building Professionals in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Architects & Building Professionals"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Architects and building professionals, while experts at shaping
								the world around us, can sometimes find themselves lost in the
								blueprints of their own business strategy. As they navigate
								through project work and client demands, it's crucial to step
								back and invest time into the structure of their business
								itself. That's where a business coach steps in - a guiding hand
								to help focus on growing and attracting new customers, but also
								working ‘on’ their vision and their business rather than just
								'in' it.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Explore how business and executive coaching can benefit your
								business
							</h3>
							<p>
								As an architect or building professional, you often find
								yourself deeply immersed in project work, making it challenging
								to step back and focus on broader business strategies. During
								our coaching sessions, we'll tackle important topics such as
								achieving a sustainable work-life balance, expanding your team,
								enhancing the quality and profitability of your projects, and
								more.{" "}
							</p>
							<p>
								With extensive experience spanning almost four decades and more
								than seven years focused specifically on coaching, I bring a
								wealth of knowledge in assisting professionals to navigate the
								unique demands of their industries. Architects and building
								professionals face the dual challenge of managing client
								expectations and operational needs while aiming to expand their
								practice. Together, we can map out a path that not only
								addresses immediate challenges but also sets the foundation for
								sustained growth and success.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Elevate your business with expert coaching
							</h3>

							<p>
								You're an architect or building professional with the expertise
								to lead complex projects from concept to completion, but when it
								comes to elevating your own business and navigating its growth,
								where do you turn? A business coach can be crucial, providing
								insights not just into efficient project management but also on
								how to strategically develop your business.
							</p>
							<p>Envision yourself:</p>
							<ul className="pl-4">
								<li>
									Enhancing work-life balance while managing demanding projects.
								</li>
								<li>
									Growing your team effectively to handle increased project
									loads.
								</li>
								<li>
									Improving the quality and profitability of your projects.
								</li>
							</ul>
							<p>
								The impact of these changes on your business, your clients, and
								your personal satisfaction can be huge. But what hurdles are
								preventing you from turning this vision into reality? Partnering
								with a business coach who understands the architectural and
								building professions can help you identify these challenges,
								overcome them, and set a clear path towards achieving your
								strategic objectives.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why haven’t you started?</h2>
							<p>
								Are you overwhelmed with project deadlines and client demands,
								finding little time to strategize and grow your architectural or
								building professional business? Do questions about expanding
								your team, enhancing project quality, or improving profitability
								often go unanswered as you navigate the complexities of your
								day-to-day operations?
							</p>
							<ul className="pl-4">
								<li>
									Do you sometimes feel isolated in the hustle of your
									profession, longing for a fresh perspective on managing your
									practice's future?
								</li>
								<li>
									Are the tasks of recruiting and motivating a skilled team
									becoming overwhelming?
								</li>
								<li>
									Have you noticed a decline in the passion and creativity that
									once drove your projects?
								</li>
								<li>
									Are you seeking to elevate your services but need the guidance
									of an experienced, unbiased, and confidential business coach?
								</li>
							</ul>
							<p>
								In the fast-paced and detail-oriented world of architecture and
								building professions, it’s not uncommon to feel bogged down by
								these challenges. However, if they are left unaddressed, they
								can stall not only your personal development and satisfaction
								but also the overall growth and efficiency of your business. Now
								is the time to tackle these issues directly with a supportive,
								non-judgmental business coach who understands the intricacies of
								your field. Together, let’s transform these obstacles into
								stepping stones for professional advancement and success.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Business and executive coaching can help you
							</h2>
							<p>
								As a business and executive coach specialising in supporting
								architects and building professionals, I understand that while
								you excel in project work, there's immense value in stepping
								back to strategically develop your business. This approach
								allows you to address aspects such as:
							</p>

							<ul className="pl-4">
								<li>Enhancing Work-Life Balance</li>
								<li>Expanding and Developing Your Team</li>
								<li>Elevating Project Quality</li>
								<li>Boosting Profitability</li>
							</ul>
							<p>
								With me as your business coach, you'll gain access to tailored
								coaching sessions designed specifically for professionals in the
								architectural and building fields. Our focus will extend across
								several important areas:
							</p>
							<ul className="pl-4">
								<li>
									Personal and Professional Growth for Architects and Building
									Professionals
								</li>
								<li>
									Leadership and Team Development Tailored to Construction and
									Design Industries
								</li>
								<li>
									Strategic Business Planning and Operational Management for
									Architectural Firms
								</li>
								<li>
									Career Advancement Strategies within the Building Sector
								</li>
							</ul>
							<p>
								My coaching method offers bespoke guidance that goes beyond
								conventional advice, providing you with a confidential space to
								explore opportunities and tackle challenges head-on. Together,
								we will address common themes like team dynamics, project
								management, and achieving an optimal work-life balance, ensuring
								that your practice not only grows but also sets new standards in
								the architectural and building industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">My experience as a business coach</h2>
							<p>
								With a robust background in business management (DMS & MBA) and
								comprehensive training in counselling and psychotherapy, coupled
								with an entrepreneurial journey that spans nearly four decades,
								I bring a deep understanding of the distinctive challenges faced
								by architects and building professionals. As a dedicated and
								unbiased business coach, I am committed to addressing the unique
								needs of this dynamic industry.{" "}
							</p>
							<p>
								My coaching services are custom-designed to support your
								professional growth and personal fulfilment. I provide a secure,
								confidential setting where you can freely explore opportunities,
								confront obstacles, and strategize. Together, we will focus on
								vital aspects such as achieving work-life balance, expanding
								your team, enhancing project quality, and boosting
								profitability. Let's work together to elevate your practice by
								spending less time working 'in' your business and more time
								working 'on' it, setting the foundation for sustained success
								and innovation in your field.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Matt Giles. Matthew Giles Architects."
						review="When I first met Jason 6 months ago, he told me “that we would be going on quite a journey together”. I had no idea what he meant at the time, now I certainly do. I was ambitious, but I felt lonely and very stuck. Jason is a breath of fresh air and gets straight to the point. He strips back what you think you know and takes you back to basics. My business is now able to move forward because I know where I want to go. Jason may take you out of your comfort zone – I say ‘let him’ – this is how we progress! In six months, I have experienced a complete change in the way in which I look at my business. I am excited about the future and changes are happening. I cannot praise Jason highly enough and I look forward to many years of working together."
						linkedIn="https://www.linkedin.com/company/matthew-giles-architects/"
						profilePic={mattG}
					/>
					<HorizontalTestimonial
						name="Tristan Bund. Tristan Bund Architecture."
						review="I’ve been working with Jason for a while now and find him to be extremely professional, supportive and a fantastic listener. His abilities go way beyond just mentoring. I trust Jason to improve any business and as such would thoroughly recommend."
						linkedIn="https://www.linkedin.com/in/tristanbund/"
						profilePic={tristan}
					/>
					<HorizontalTestimonial
						name="Mark Wiffen. Marlin Architectural Design Ltd"
						review="I have been working with Jason since the beginning of 2021 and in that time have started outsourcing certain areas of my work as a direct result of Jason's encouragement and guidance. This is now enabling me to bring forward my life-long plan of reducing my working week to 4 days to the beginning of 2022, whereas previously it would have been April 2023. Jason has a knack of subtly pushing you further than you feel you want to go. This is enabling me to drive the business forward taking on more work to improve profits at the same time as reducing my hours. Anyone needing business advice would benefit to the better for their sessions with Jason."
						linkedIn="https://www.linkedin.com/in/mark-wiffen-3244a427/"
						profilePic={mark}
					/>
				</Container>
			</section>

			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a call together</h3>
							<p>
								You are frequently immersed in project work, which makes it
								crucial to occasionally step back and focus on what you want and
								the business itself, not just the projects. You excel in
								designing and building, understanding the intricacies of each
								project's demands on time, quality, and profitability. Yet,
								thriving in your field often means enhancing not just the
								technical skills but also your vision, leadership, team growth,
								and work-life balance.
							</p>
							<p>
								Let’s schedule a discovery call to familiarise ourselves with
								each other and explore how business and executive coaching can
								be specifically tailored for you. This is your chance to work
								'on' you and your business and to maximise the quality of your
								projects and the profitability of your practice.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default WebDesignersAndDigitalMarketers;
